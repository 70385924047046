import React from "react"
import { graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import Layout from "../layout"
import SEO from "../layout/seo"
import { useSlateSerialiser } from "../hooks/useSlateSerialiser"
import parse from "html-react-parser"

const MainContainer = tw.article`
max-w-3xl sm:mx-auto flex flex-col
`
const Image = styled.img`
  max-height: 75vh;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) fff, #000 100%);
  ${tw`pb-6 w-full object-cover`};
`

const Title = tw.h1`
text-3xl leading-tight md:text-4xl lg:text-5xl mb-6 lg:mb-8 text-gray-800 px-2 font-bold
`
const Summary = tw.p`
leading-normal text-base md:text-lg mb-6 text-gray-700 px-2
`
const ContentContainer = tw.article`
mb-6 md:pb-12 text-gray-800 px-2 min-w-full prose prose lg:prose-lg xl:prose-xl
`

const BlogPost = ({ data }) => {
  const blogHTML = useSlateSerialiser(
    data.webinyHeadlessCms.getBlogPosts.data.body
  )

  return (
    <>
      <SEO
        title={data.webinyHeadlessCms.getBlogPosts.data.title}
        description={data.webinyHeadlessCms.getBlogPosts.data.summary}
        article={true}
        image={data.webinyHeadlessCms.getBlogPosts.data.image + "?width=1400"}
      />
      <Layout>
        <Image src={data.webinyHeadlessCms.getBlogPosts.data.image} />
        <MainContainer>
          <Title>{data.webinyHeadlessCms.getBlogPosts.data.title}</Title>
          <Summary>{data.webinyHeadlessCms.getBlogPosts.data.summary}</Summary>
          <ContentContainer>{parse(blogHTML)}</ContentContainer>
        </MainContainer>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($id: ID!) {
    webinyHeadlessCms {
      getBlogPosts(where: { id: $id }) {
        data {
          slug
          id
          title
          summary
          image
          body
        }
      }
    }
  }
`

export default BlogPost
